// extracted by mini-css-extract-plugin
export var artworkImg = "staccienawiecej-module--artwork-img--Zk0FQ";
export var bodyCol = "staccienawiecej-module--body-col--BIJUe";
export var copyright = "staccienawiecej-module--copyright--4gDyi";
export var credits = "staccienawiecej-module--credits--5rxix";
export var hl = "staccienawiecej-module--hl--n3tbH";
export var lnkContainer = "staccienawiecej-module--lnk-container--11yyW";
export var lnkWrapper = "staccienawiecej-module--lnk-wrapper--wdqc8";
export var logo = "staccienawiecej-module--logo--Gerpm";
export var streamIco = "staccienawiecej-module--stream-ico--CJ0Vq";
export var streamIcoDisabled = "staccienawiecej-module--stream-ico-disabled--uyJO5";
export var streamList = "staccienawiecej-module--stream-list--IHzYp";
export var titl = "staccienawiecej-module--titl--UkurP";